.StatsTodayTable {    
    width: 100%;
    border-collapse: collapse;
}      

.GameOngoing {
    color: rgb(189, 214, 150);
}

.NotPlaying {
    opacity: 0.5;
}

.MainStatsToday {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.NoWrap {
    width: 100%;
    vertical-align: bottom;
    white-space: nowrap;
}

.XSmallRightFloatText {    
    font-size: x-small;
    color: white;  
    float: right;
}

a:link { color: lightskyblue; }
a:visited { color: #8d6bad; }
a:hover { color: #ffffff; }
a:active { color: #ff4040; text-decoration:none; font-weight:normal; }