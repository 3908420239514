.Page {
  background-color: black;
}

.Main20p {  
  padding-bottom: 24px;
  margin-left: 0px;  
  background-color: black;
  color: #dddddd;
  font-size: small;
  width: 100%;      
  margin: 0;    
}

.Progress {
  text-align: center;
}

.ProgressSmallPrint {
  margin-top: 32px;
  color: #888888;
  font-size: xx-small;
}

.Footer {
  background-color: #000;
  color: #fff;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Ensure footer stays on top of other content */
}

.LoadingIndicator {
  font-size: small;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;  
}

.Heading {
  text-align: center;
  max-width: 750px;
  color: white;
}

